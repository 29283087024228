/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './WebsitePartnerForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import FileInput from '../../common/file-input/FileInput';
import checkRes from '../../utils/checkRes';
import { setSelectedTableRow } from '../../redux/website-partners-slice/websitePartnersSlice';
import {
  createWebsitePartnerAction,
  updateWebsitePartnerAction
} from '../../redux/website-partners-slice/websitePartnersSliceThunkApi';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل الاسم بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل الاسم بالانجليزية')
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  image: null
};

const WebsitePartnerForm = ({ fetchedTableData }) => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.websitePartners.selectedTableRow
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('desc_ar', selectedTableRow?.desc_ar || '');
      setValue('desc_en', selectedTableRow?.desc_en || '');
    }
  }, [selectedTableRow, fetchedTableData]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }

    if (!selectedTableRow) {
      const action = await dispatch(
        createWebsitePartnerAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
      }
    } else {
      formData.append('partner_id', selectedTableRow?.id || '');

      const action = await dispatch(
        updateWebsitePartnerAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
      }
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form website-partner-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>الاســـم بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_ar"
            type="text"
            placeholder={'الاســـم بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.name_ar?.message}
            validateStatus={errors?.name_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>الاســـم بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_en"
            type="text"
            placeholder={'الاســـم بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                صورة الخدمة
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset(emptyFormValues);
              dispatch(setSelectedTableRow(null));
              setFiles(null);
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default WebsitePartnerForm;
