/* eslint-disable eqeqeq */


import TrashIcon from '../../common/icons/TrashIcon.jsx';
import EditIcon from '../../common/icons/EditIcon.jsx';

const CountriesTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };
  const handleDeletePackage = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };


  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleDeletePackage(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </button>
        </div>

        
      </div>
    );
  };

  return [ {
    title: '#',
    dataIndex: 'id',
    render: (_, record) => {
      return record?.id || '----';
    }
  },
 
    {
      title: 'دوله',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },  
    {
      title: 'العملة',
      dataIndex: 'currency_ar',
      render: (_, record) => {
        return record?.currency_ar || '----';
      }
    },
    
   
      {
      title: 'الحالة',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <div className="accept-reject-btns">
          {record?.status == 1 ? ("مفعل"):("غير مفعل")}
        </div>
        );
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {record?.id === 1 ? '---' : renderActions(record)}
          </div>
        );
      }
    },
  ];
};

export default CountriesTableColumns;
