export const sortPackagesValues = {
 Desc: 1,
 Asc: 2,
  priceDesc: 3,
  priceAsc: 4,
};

export const getSortPackagesArr = () => {
  return [
    {
      id: 1,
      name: 'من الأحدث إلى الأقدم',
      value: sortPackagesValues.Desc
    },
    {
      id: 2,
      name: 'من الأقدم إلى الأحدث',
      value: sortPackagesValues.Asc
    },
    {
      id: 3,
      name: 'السعر: من الاكبر الي الاصغر  ',
      value: sortPackagesValues.priceDesc
    },
    {
      id: 4,
      name: 'السعر: من الاصغر الي الاكبر ',
      value: sortPackagesValues.priceAsc
    },
  ];
};
