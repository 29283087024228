const sharedRouterLinks = {
  // merchentDashboard: '/merchent-dashboard',
  homeRoute: '/',
  profileRoute: '/profile',
  editProfileRoute: '/profile/edit',
  settingsRoute: '/settings',
  createAdminFormRoute: '/settings/admins/create',
  updateAdminFormRoute: (adminId) =>
    adminId
      ? `/settings/admins/update/${adminId}`
      : '/settings/admins/update/:adminId',
  //
  appClientsRoute: '/clients',
  appSingleClientRoute: (client_id) =>
    client_id
      ? `/clients/client-details/${client_id}`
      : '/clients/client-details/:client_id',
  appSingleClientInvoiceRoute: (client_id, invoice_id) =>
    client_id && invoice_id
      ? `/clients/${client_id}/invoices/${invoice_id}`
      : '/clients/:client_id/invoices/:invoice_id',
  appAgentsRoute: '/influencers',
  singleInfluencerRoute: (influencer_id) =>
    influencer_id
      ? `/influencers/influencer-details/${influencer_id}`
      : '/influencers/influencer-details/:influencer_id',
  appPackagesRoute: '/app-packages',
  devicesRoute: '/devices',
  appPaymentTypesRoute: '/payment-types',
  appArchivesRoute: '/archived',
  appCountryRoute: '/country', appAdminRoute: '/admins',
  appGuideRoute: '/app-guide',
  //
  websiteAdminPanelRoute: '/website-panel',
  websitesServicesRoute: '/website-services',
  websitesServicesFeatruesRoute: '/website-services-features',
  websitePartnersRoute: '/website-partners',
  websitePackagesRoute: '/website-packages',
  websiteContactsRoute: '/website-contacts',
  websiteStartExpRoute: '/website-starte-experience'
  //
};

export default sharedRouterLinks;
