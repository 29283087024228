import { createAsyncThunk } from '@reduxjs/toolkit';

import customApiRequest from '../../helpers/customApiRequest';
import getAllCountriesApi from '../../apis/countries-apis/getAlCountriesApi.js';
import createCountryApi from '../../apis/countries-apis/createCountrieApi.js';
import deleteCountryApi from '../../apis/countries-apis/deleteCountrieApi.js';

export const getCountriesAction = createAsyncThunk(
  'countries/getCountriesAction',
  async (v, thunkAPI) => {    
    return customApiRequest({
      apiCall: await getAllCountriesApi(),
      thunkAPI
    });
  }
);

export const createCountryAction = createAsyncThunk(
  'country/create',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const updateCountryAction = createAsyncThunk(
  'country/edit',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createCountryApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);

export const deleteCountryAction = createAsyncThunk(
  'country/delete',
  async ( {dataToBeSubmitted} , thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteCountryApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
