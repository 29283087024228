import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createCountryAction,
  deleteCountryAction,
  updateCountryAction,
  getCountriesAction
} from './countriesSliceThunkApis';

const initialState = {
  isLoadingJsonData: false,
  jsonData: [],
  /////////////////////
  isLoadingTableData: false,
  fetchedCountries: [],
  fetchDataCount: 0,

  countryFormOpened: false,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setIsLoadingCountries: (stateSlice, action) => {
      stateSlice.isLoadingTableData = action.payload;
    },
    setCountryFormOpenedAction: (sliceState, action) => {
      sliceState.countryFormOpened = action.payload;
      if (action.payload === false) {
        sliceState.selectedTableRow = null;
      }
      window.scrollTo(0, 0);
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      
      sliceState.selectedTableRow = action.payload;
    },
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchedCountries = action.payload;
    },
    setFetchedCountries: (sliceState, action) => {
      sliceState.fetchedCountries = action.payload;
    }
  },

  extraReducers: {
    // get all devivces
    [getCountriesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getCountriesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      
      state.fetchedCountries = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;

    },
    [getCountriesAction.rejected]: (state) => {
      state.isLoadingTableData = false;
    },
    // create countrie
    [createCountryAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createCountryAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.countryFormOpened = false;
      }
    },
    [createCountryAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // edit countrie
    [updateCountryAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateCountryAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.countryFormOpened = false;
      }
    },
    [updateCountryAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    [deleteCountryAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteCountryAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteCountryAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const countriesReducer = countriesSlice.reducer;

export const {
  setCountryFormOpenedAction,
  setIsLoadingCountries,
  setDeleteModalOpened,
  setSelectedTableRow,
  setFetchedCountries
} = countriesSlice.actions;

export default countriesReducer;
