/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './CountryForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs.js';
import AntdTextField from '../../common/antd-form-components/AntdTextField.jsx';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon.jsx';
import FileInput from '../../common/file-input/FileInput.jsx';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption.jsx';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon.jsx';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon.jsx';
import { getPackagesStatusArr } from '../../const-values/packagesStatus.js';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setCountryFormOpenedAction } from '../../redux/countries-slice/countriesSlice.js';
import { createCountryAction, updateCountryAction } from '../../redux/countries-slice/countriesSliceThunkApis.js';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل الاسم بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل الاسم بالإنجليزية'),
  currency_ar: Yup.string().required('من فضلك ادخل العملة بالعربية'),
  currency_en: Yup.string().required('من فضلك ادخل العملة بالإنجليزية'),
  status: Yup.string().required('من فضلك اختار الحالة'),
  countryCode: Yup.string()
    .required('من فضلك ادخل كود الدولة')
    .matches(/^[A-Z,a-z]{2,3}$/, 'يجب أن يتكون كود الدولة من حرفين أو ثلاثة'),
  phoneNumbers: Yup.string()
    .required('من فضلك ادخل رقم الهاتف')
    .matches(/^[0-9,+]+$/, 'يجب أن يتكون رقم الهاتف من أرقام فقط'),
  code: Yup.string()
    .required('من فضلك ادخل الكود')
    .matches(/^[0-9]+$/, 'يجب أن يتكون الكود من أرقام فقط')
});

const { medalIcon, imageIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  currency_ar: '',
  currency_en: '',
  status: '',
  countryCode: '',
  phoneNumbers: '',
  code: ''
};

const CountryForm = () => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.countries.selectedTableRow
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });



  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('currency_ar', selectedTableRow?.currency_ar || '');
      setValue('currency_en', selectedTableRow?.currency_en || '');
      setValue('status', String(selectedTableRow.status));

      setValue('countryCode', selectedTableRow?.countryCode || '');
      setValue('phoneNumbers', selectedTableRow?.phoneNumbers || '');
      setValue('code', selectedTableRow?.code || '');
    }
  }, [selectedTableRow]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('currency_ar', data.currency_ar || '');
    formData.append('currency_en', data.currency_en || '');
    formData.append('status', data.status || '');
    formData.append('countryCode', data.countryCode || '');
    formData.append('phoneNumbers', Number(data.phoneNumbers) || '');
    formData.append('code', data.code || '');

    if (data?.icon?.length > 0) {
      formData.append('icon', data.icon[0]);
    }

    if (!selectedTableRow) {
      dispatch(
        createCountryAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('country_id', selectedTableRow?.id || '');

      dispatch(
        updateCountryAction({
          dataToBeSubmitted: formData,
          id: selectedTableRow?.id,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="custom-shared-form country-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة دولة جديدة
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setCountryFormOpenedAction(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الدولة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الدولة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الدولة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الدولة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>عملة الدولة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="currency_ar"
              type="text"
              placeholder={'عملة الدولة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.currency_ar?.message}
              validateStatus={errors?.currency_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>عملة الدولة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="currency_en"
              type="text"
              placeholder={'عملة الدولة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.currency_en?.message}
              validateStatus={errors?.currency_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>كود الدولة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="countryCode"
              type="text"
              placeholder={'كود الدولة...'}
              // label="الاســــم"
              errorMsg={errors?.countryCode?.message}
              validateStatus={errors?.countryCode ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>       رقم الهاتف </span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="phoneNumbers"
              type="text"
              placeholder={'     رقم الهاتف ..'}
              // label="الاســــم"
              errorMsg={errors?.phoneNumbers?.message}
              validateStatus={errors?.phoneNumbers ? 'error' : ''}
              control={control}
            />
          </div>

        </div>
        {/*  */}


        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>كودالدولة  </span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="code"
              type="text"
              placeholder={' كودالدولة...'}
              // label="الاســــم"
              errorMsg={errors?.code?.message}
              validateStatus={errors?.code ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="select-label-wrap shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              حالة الدولة
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`status`}
                errorMsg={errors?.status && errors.status.message}
                validateStatus={errors?.status ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="حالة الدولة"
                options={
                  getPackagesStatusArr()?.length > 0 &&
                  getPackagesStatusArr().map((obj) => ({
                    title: obj?.name,
                    value: obj?.value
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="country-form"
              />
            </div>
          </div>

          {/*  */}
        </div>

  
        {/* END DESC */}

        <div className="file-inputs-wrap">
          <FileInput
            name="icon"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                  ايقون الدولة
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.icon?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setCountryFormOpenedAction(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>
    </Form>
  );
};

export default CountryForm;
