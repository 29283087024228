/* eslint-disable react-hooks/exhaustive-deps */
import './CountryPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { AnimatePresence, motion } from 'framer-motion';
import CountryPageFilter from './CountryPageFilter';
import CountryPageTable from './CountryPageTable';
import {
  deleteCountryAction,
  getCountriesAction
} from '../../redux/countries-slice/countriesSliceThunkApis.js';
import CountryForm from './CountryForm.jsx';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal.jsx';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes.js';
import {
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/countries-slice/countriesSlice.js';

const CountryPage = () => {
  const dispatch = useDispatch();
  const isLoadingTableData = useSelector(
    (store) => store.countries.isLoadingTableData
  );
  const fetchCountriesCount = useSelector(
    (store) => store.countries.fetchDataCount
  );
  const isSubmitting = useSelector((store) => store.countries.isSubmitting);
  const countryFormOpened = useSelector(
    (store) => store.countries.countryFormOpened
  );
  const deleteModalOpened = useSelector(
    (store) => store.countries.deleteModalOpened
  );
  const selectedTableRow = useSelector(
    (store) => store.countries.selectedTableRow
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;
  useEffect(() => {
    if (isMounted) dispatch(getCountriesAction());
    return function () {
      isMounted = false;
    };
  }, [fetchCountriesCount]);
  const handleDeletePackage = () => {
    if (isMounted && selectedTableRow?.id) {      
      dispatch(
        deleteCountryAction({
          dataToBeSubmitted: selectedTableRow.id
        })
      );
    }
  };
  if (isLoadingTableData) {
    return (
      <div className="archives-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="clients-page">
      <div className="page-content">
        <AnimatePresence>
          {!countryFormOpened && (
            <motion.div
              // layout
              initial={{
                width: 0,
                height: 0,
                opacity: 0,
                translateX: 700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  width: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              animate={{
                width: '100%',
                height: 'auto',
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.8 },
                  width: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              exit={{
                width: 400,
                height: 0,
                opacity: 0,
                translateX: 400,
                transition: {
                  opacity: { ease: 'linear', duration: 0.5 },
                  width: { ease: 'linear', duration: 0.5 },
                  translateX: { ease: 'linear', duration: 0.5 }
                }
              }}
              className="page-main-content"
            >
              <div className="page-title">الدول</div>
              <CountryPageFilter />
              <CountryPageTable />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {countryFormOpened && (
            <motion.div
              initial={{
                opacity: 0,
                translateX: 700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              exit={{
                opacity: 0,
                translateX: 700,
                height: 0,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              className="device-form-wrapper"
            >
              <CountryForm />
            </motion.div>
          )}
        </AnimatePresence>{' '}
      </div>

      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(setDeleteModalOpened(v));
        }}
        handleAccept={handleDeletePackage}
      >
        <h3>{`هل انت متأكد من حذف الدولة ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default CountryPage;
