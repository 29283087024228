import { configureStore } from '@reduxjs/toolkit';
import appGuideReducer from './app-guide-slice/appGuideSlice';
import archiveReducer from './archive-slice/archiveSlice';
import brandsReducer from './brands-slice/brandsSlice';
import clientsReducer from './clients-slice/clientsSlice';
import counterReducer from './counter/counterSlice';
import devicesReducer from './devices-slice/devicesSlice';
import influencersReducer from './influcencers-slice/influencersSlice';
import packagesReducer from './packages-slice/appPackagesSlice';
import paymentTypesReducer from './payment-types-slice/paymentTypesSlice';
import sectionTitlesReducer from './section-titles-slice/SectionTitlesSlice';
import settingsReducer from './settings-slice/settingsSlice';
import websiteContactsReducer from './website-contacts-slice/websiteContactsSlice';
import websiteExperiencesReducer from './website-experiences-slice/websiteExperiencesSlice';
import websitePackagesReducer from './website-packages-slice/websitePackagesSlice';
import websitePartnersReducer from './website-partners-slice/websitePartnersSlice';
import websiteServicesReducer from './website-services-slice/websiteServicesSlice';
import countriesReducer from './countries-slice/countriesSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    clients: clientsReducer,
    archive: archiveReducer,
    appGuide: appGuideReducer,
    paymentTypes: paymentTypesReducer,
    settings: settingsReducer,
    appPackages: packagesReducer,
    devices: devicesReducer,
    countries: countriesReducer,
    brands: brandsReducer,
    influencers: influencersReducer,
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    sectionTitles: sectionTitlesReducer,
    websiteServices: websiteServicesReducer,
    websitePartners: websitePartnersReducer,
    websitePackages: websitePackagesReducer,
    websiteExperiences: websiteExperiencesReducer,
    websiteContacts: websiteContactsReducer
  }
});
